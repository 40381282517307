const WinnerTemplate = ({
    image,
    prize,
    solution,
    prototype
}) => {
    return ( 
            <div className="winner">
                <img className="pic" src={image} alt="image" />              
                <div className="divder">
                    <p className="prize nomargin">{prize}</p>
                    <p className="solution nomargin">{solution}</p>
                    <a href={prototype} target="_blank"><button className="prototype nomargin">Click Here to See Prototype!!</button></a>
                    
                </div>
            </div>
     );
}
 
export default WinnerTemplate;