import { useState } from 'react';
import banner from '../banner.svg';
import year from '../year.svg';
import slogan from '../slogan.svg';
import bottom from '../bottom.svg';

const Banner = () => {
    
    // Updat timer
    const [days, setdays] = useState(0)
    const [hours, sethours] = useState(0)
    const [minutes, setminutes] = useState(0)
    const [seconds, setseconds] = useState(0)

    // Set WTH Day/Time
    var countDownDate = new Date("Dec 20, 2024 08:00:00").getTime();

    var timeCounter = setInterval(function() {
        
        // Get current time
        var current = new Date().getTime();

        // Get time difference
        var distance = countDownDate - current;

        //Calculate time
        setdays(Math.floor(distance / (1000 * 60 * 60 * 24)));
        sethours(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        setminutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        setseconds(Math.floor((distance % (1000 * 60)) / 1000));

    },1000)

    return ( 
        <div className="banner">
            <div className='banner-details'>
                <div className='banner-image'>
                    <img className='word_logo' src={year} alt="WHAT THE HACK 2024" />
                    <img className='banner_logo' src={banner} alt="banner" />
                    <img className='word_logo' src={slogan} alt="HACK IT UP A NOTCH" />
                </div>
                <p>21st to 22nd December 2024
                    <br/>
                    @Singapore University of Technology and Design</p>

                    <p className='large nomargin signup'><a href="https://forms.office.com/r/Vv5002fu2b" target="_blank"><strong>SIGN UP NOW!!</strong></a></p>

                <div className='timer'>
                    <div className='days'>
                        <p>DAYS</p>
                        <p><strong className='large'>{days}</strong></p>
                    </div>
                    <div className='hours'>
                        <p>HOURS</p>
                        <p><strong className='large'>{hours}</strong></p>
                    </div>
                    <div className='minutes'>
                        <p>MINUTES</p>
                        <p><strong className='large'>{minutes}</strong></p>
                    </div>
                    <div className='seconds'>
                        <p>SECONDS</p>
                        <p><strong className='large'>{seconds}</strong></p>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Banner;