import './App.css';
import About from './components/About';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom"
import Prizes from './components/Prizes';
import Banner from './components/banner';
import Faq from './components/Faq';
import Past from './components/Past';
import Scrollbtn from './components/scrollbtn';
import { useRef } from 'react';
import Navbar from './components/Navbar';

function App() {
  const top = useRef(null);
  const about = useRef(null);
  const prizes = useRef(null);
  const past = useRef(null);
  const faq = useRef(null);
  return (
        <div className="App" ref={top}>
          <Router>
          <Navbar className="stay" {...{about, prizes, past, faq}}></Navbar>
          <Banner></Banner>
          <Scrollbtn {...{top}}/>
            <Routes>
              <Route exact path="/" ref={about} element={<About/>} />
              <Route exact path="/prizes" ref={prizes} element={<Prizes/>} />
              <Route exact path="/past" ref={past} element={<Past/>} />
              <Route exact path="/faq" ref={faq} element={<Faq/>} />
            </Routes>
          </Router>
        </div>   
  );
}

export default App;
