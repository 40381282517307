import { Link, useHistory } from 'react-router-dom';
import logo from '../logo.svg';

const Header = ({aboutRef, prizesRef, scheduleRef,faqRef}) => {

    const handleScroll = (refPoint) => {
        switch (refPoint) {
            case "about":
                aboutRef.current.scrollIntoView();
                break;
            case "prizes":
                prizesRef.current.scrollIntoView();
                break;
            case "schedule":
                scheduleRef.current.scrollIntoView();
                break;
            case "faq":
                faqRef.current.scrollIntoView();
                break;
            default:
                aboutRef.current.scrollIntoView();
                break;
        }
    }

    return ( 
        <div className="header" >
                <div className="left">
                    <img className='logo' src={logo} alt="Logo" />
                </div>
                <div className="right">
                    <p><Link to='/' className='link' onClick={() => handleScroll("about")}>About</Link></p>
                    <p><Link to='/prizes' className='link' onClick={() => handleScroll("prizes")}>Prizes</Link></p>
                    <p><Link to='/past' className='link' onClick={() => handleScroll("schedule")}>Past Winners</Link></p>
                    <p><Link to='/faq' className='link' onClick={() => handleScroll("faq")}>FAQ</Link></p>
                    <p><a href="https://forms.office.com/r/Vv5002fu2b" target="_blank">Sign Up</a></p>
                </div>
            </div>
     );
}
 
export default Header;