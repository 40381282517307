import WinnerTemplate from "./WinnerTemplate";
import WinnerTemplate1 from "./WinnerTemplate1";
import { useContext } from 'react';
import { TodoContext2023,TodoContext2022,TodoContext2018,TodoContext2017 } from './WinnerDetails';
import bottom2022 from "../winners/bottom2022.svg"
import bottom from '../bottom.svg';

const Past = () => {
    const {details2023} = useContext(TodoContext2023);
    const {details2022} = useContext(TodoContext2022);
    const {details2018} = useContext(TodoContext2018);
    const {details2017} = useContext(TodoContext2017);

    return ( 
        <div className="past">
            <p className="intro"><strong>Past Winners</strong> provides insights into the standards and quality 
                of the WTH and helps you to set goals by learning from their experience. It can serve 
                as a source of motivation and inspiration, encouraging you to aim for similar success.
            </p>
            <div className="display2023">
              <p className="large nomargin">2023 WTH</p>
              <p className="large nomargin">Theme: A Better World</p>
              {
                details2023.map((winnerDetail,key) =>(
                    <WinnerTemplate {...{name: winnerDetail.name, image: winnerDetail.image, prize: winnerDetail.prize, solution: winnerDetail.solution, prototype: winnerDetail.prototype, id: winnerDetail.id}} key={key}/>
                ))
              }
              <img className='bottomdivider' src={bottom} alt="Bottom" />
          </div>

          <div className="display2022">
              <p className="large nomargin">2022 WTH</p>
              <p className="large nomargin">Theme: A Brighter Future</p>
              {
                details2022.map((winnerDetail,key) =>(
                    <WinnerTemplate1 {...{name: winnerDetail.name, image: winnerDetail.image, prize: winnerDetail.prize, solution: winnerDetail.solution, prototype: winnerDetail.prototype, id: winnerDetail.id}} key={key}/>
                ))
              }
              <img className='bottomdivider' src={bottom} alt="Bottom" />
          </div>
          
          <div className="display2018">
              <p className="large nomargin">2018 WTH</p>
              <p className="large nomargin">Theme: Immersive Realities Edition</p>
              {
                details2018.map((winnerDetail,key) =>(
                    <WinnerTemplate {...{name: winnerDetail.name, image: winnerDetail.image, prize: winnerDetail.prize, solution: winnerDetail.solution, prototype: winnerDetail.prototype, id: winnerDetail.id}} key={key}/>
                ))
              }
              <img className='bottomdivider' src={bottom} alt="Bottom" />
          </div>

          <div className="display2017">
              <p className="large nomargin">2017 WTH</p>
              {
                details2017.map((winnerDetail,key) =>(
                    <WinnerTemplate1 {...{name: winnerDetail.name, image: winnerDetail.image, prize: winnerDetail.prize, solution: winnerDetail.solution, prototype: winnerDetail.prototype, id: winnerDetail.id}} key={key}/>
                ))
              }
              <img className='bottom' src={bottom} alt="Bottom" />
          </div>

        </div>
        
     );
}
 
export default Past;