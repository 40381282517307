import scroll from '../top.svg';

const scrollbtn = ({top}) => {

    function scrollup() {
        console.log(top)
        top.current.scrollIntoView({ behavior: 'smooth'})
    }
    return ( 
        <div className="scroll" onClick={() => scrollup()}>
                <img src={scroll}  />
        </div>
     );
}
 
export default scrollbtn;